$(function() {

    const staffItems = $(".about__team .about__team__item");

    staffItems.find(".dropdown__toggle").on("click", function() {
        const clickedIndex = $(this).index(".dropdown__toggle");

        if (!staffItems.eq(clickedIndex).hasClass("open")) {
            $(staffItems, "open").removeClass("open");
            staffItems.eq(clickedIndex).toggleClass("open");
        } else {
            $(staffItems, "open").removeClass("open");
        }
    })

    staffItems.each(i => {
        staffItems.eq(i).css("transition-delay", `${.25 * (i / 2)}s`)
    })

})