$(function () {

    const boxLinks = $(".dynamic-boxes .box__link");

    resizeBoxes();
    $(window).on("resize", function () {
        resizeBoxes();
    })

    function resizeBoxes() {

        let width = boxLinks.find("figure").width();

        boxLinks.find("figure").css({ "height": width, "max-height": width })
    }

})