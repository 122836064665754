$(function () {

    const headerMobileTrigger = $(".site__header #mobile-trigger");
    const headerLinks = $(".site__header .quick__tabs");

    headerMobileTrigger.on("click", function() {
        headerMobileTrigger.toggleClass("open")
        headerLinks.toggleClass("open")
    })

})