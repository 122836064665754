$(function () {
    const benefitsSlider = $(".benefits__slider #slider");
    benefitsSlider.flickity({
        contain: true,
        cellAlign: "left",
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        adaptiveHeight: true,
        on : {
            ready : function() {
                $(".benefits__slider #slider .flickity-slider").height($(".benefits__slider .flickity-viewport").height())
            }
        }
    })

    $("#benefits-slide-prev").on("click", function () {
        benefitsSlider.flickity("previous");
    })

    $("#benefits-slide-next").on("click", function () {
        benefitsSlider.flickity("next");
    });

    $(".benefits__card").each(i => {
        $(".benefits__card").eq(i).css("transition-delay", `${.25 * (i / 2)}s`);
    })

})