$(function () {

    if ($("body").hasClass("basket")) {

        const basketAgeCheckBox = $("#basket-age-check");
        const basketWaiverCheckBox = $("#basket-waiver-check");

        //Clear input values on load
        basketAgeCheckBox.find("input").prop("checked", false);
        basketWaiverCheckBox.find("input").prop("checked", false);

        basketAgeCheckBox.on("change", function () {
            let checkboxVal = $(this).find("input")[0].checked;

            if (checkboxVal) {

                basketWaiverCheckBox.css("display", "flex");

            } else {
                basketWaiverCheckBox.css("display", "none");
                basketWaiverCheckBox.find("input").prop("checked", false);
            }
        })

    }

    //Booking module
    const classesString = ".booking__module .input__wrapper.checkbox input";
    const selectBoxes = $(classesString);

    selectBoxes.on("click", function () {  
        
        const currentIndex = ($(this).index(classesString) + 1) * 6;
        $(".booking__module td").eq(currentIndex - 1).toggleClass("selected")

    })
})