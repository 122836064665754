$(function () {

    const mobileTabSelect = $(".tabs__navigation__mobile select");
    const tabLinks = $(".tabs__navigation ul li a");

    const enquiryForm = $(".enquiry__form");

    if ($("body").hasClass("enquire")) {

        highlightSelect(0);

        mobileTabSelect.on("change", function () {

            let selectedIndex = $(mobileTabSelect)[0].selectedIndex;
            mobileTabSelect[0].classList = "";
            highlightSelect(selectedIndex);
        })

        tabLinks.on("click", function () {

            //Remove active class for colur
            $(tabLinks, "active").removeClass("active");

            const clickedIndex = $(this).index(".tabs__navigation ul li a");
            $(this).addClass("active");
            highlightSelect(clickedIndex);
        })
    }

    function highlightSelect(index) {
        $(".enquiry__form")[0].classList = "enquiry__form";

        mobileTabSelect.addClass("course");

        if (isReferred) {

            switch (isReferred) {
                case "course":
                    tabLinks.eq(0).addClass("active");
                    break;
                case "venue":
                    tabLinks.eq(1).addClass("active");
                    break;
                case "sport":
                    tabLinks.eq(2).addClass("active");
                    break;
                case "about":
                    tabLinks.eq(3).addClass("active");
                    break;
                case "enquire":
                    tabLinks.eq(3).addClass("active");
                    break;
                default:
                    tabLinks.eq(0).addClass("active");
                    break;
            }
        } else {
            tabLinks.eq(index).addClass("active");
        }

        switch (index) {
            case 0:
                mobileTabSelect.addClass("course");
                enquiryForm.addClass("course");
                break;
            case 1:
                mobileTabSelect.addClass("venue");
                enquiryForm.addClass("venue");
                break;
            case 2:
                mobileTabSelect.addClass("sport");
                enquiryForm.addClass("sport");
                break;
            case 3:
                mobileTabSelect.addClass("about");
                enquiryForm.addClass("about");
                break;
        }
    }

    window.addEventListener("message", event => {
        if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormReady") {
            $(`#hsForm_${event.data.id} fieldset`).each(function (obj) {
                if ($(this).height() === 0) {
                    $(this).css({ "display": "none" });
                } else {
                    if ($(this).find("select").length) {

                        if ($(this).find("label").text().toLowerCase().replace("*", "") === "enquiry type" ||
                            $(this).find("label").text().toLowerCase().replace("*", "") === "event type" ||
                            $(this).find("label").text().toLowerCase().replace("*", "") === "booking type") {
                            $(this).find("select.hs-input").wrap("<div class='select__wrapper type'></div>");
                        } else {
                            $(this).find("select.hs-input").wrap("<div class='select__wrapper'></div>");
                        }
                    }
                }
            })
        }
    });
})