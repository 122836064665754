$(function () {

    if ($("body").hasClass("home") || $("body").hasClass("venue") || $("body").hasClass("sport")) {

        let sliderNavIndexEl = $(".slider__nav .count span.current-slide");
        let sliderNavCountEl = $(".slider__nav .count span.current-count");
        let slideCount = $(".venue__slider .venue__slider__item").length;

        const slidePlayButton = $(".play-button");
        let isPlaying = false;

        const venueSlider = new Flickity(".venue__slider div#slider", {
            wrapAround: false,
            draggable: true,
            prevNextButtons: false,
            groupCells: "80%",
            center: true,
            cellAlign: "center",
            contain: false,
            adaptiveHeight: true,
            imagesLoaded: true,
            pageDots: false,
            on: {
                ready: function () {
                    sliderNavIndexEl.text("1");
                    sliderNavCountEl.text(slideCount);

                    //Initialise the bar width
                    updateBar(1);
                },
                change: function (index) {
                    sliderNavIndexEl.text(index + 1);
                    updateBar(index + 1);
                }
            }
        });

        //Controls
        $(".slider__nav .controls .controls__prev").on("click", () => {

            //If box is open on current slide, hide and resize
            if ($(".venue__slider__item").eq(venueSlider.selectedIndex).hasClass("open")) {
                $(".venue__slider__item.open").removeClass("open");
                venueSlider.resize();
            }

            venueSlider.previous();
            updateBar(venueSlider.selectedIndex + 1);
        })

        $(".slider__nav .controls .controls__next").on("click", () => {

            //If box is open on current slide, hide and resize

            if ($(".venue__slider__item").eq(venueSlider.selectedIndex).hasClass("open")) {
                $(".venue__slider__item.open").removeClass("open");
                venueSlider.resize();
            }

            venueSlider.next();
            updateBar(venueSlider.selectedIndex + 1);
        })

        function updateBar(index) {
            $(".slider__nav .progress .progress-current").width((index / slideCount) * 100 + "%");
        }

        //Listen for click on slider dropdown for mobile
        $(".venue__slider").find(".venue__slider__toggle").on("click", function () {
            const clickedIndex = $(this).index(".venue__slider__toggle");

            //Toggle dropdown for slider box
            $(".venue__slider").find(".venue__slider__item").eq(clickedIndex).toggleClass("open");
            venueSlider.resize();
        })


        //Slider video handler
        slidePlayButton.on("click", function() {
            
            if (!isPlaying) {
                $(this).prev().trigger("play");
                $(this).parent().addClass("playing")
                return isPlaying = true;
            }

            $(this).prev().trigger("pause");
            $(this).parent().removeClass("playing")
            return isPlaying = false;

        })
    }

    if ($("body").hasClass("venue")) {

        const venueSpaceBoxSlider = $(".venue__box__slider .tab__slider").flickity({
            draggable: true,
            prevNextButtons: false,
            cellAlign: "right",
            contain: true,
            pageDots: false
        });

        const venueLayoutImages = $(".venue__layout__img");
        const venueLayoutListItems = $(".layout__info__options li");

        $("#venue-box-slide-prev").on("click", function () {
            venueSpaceBoxSlider.flickity("previous");
        })

        $("#venue-box-slide-next").on("click", function () {
            venueSpaceBoxSlider.flickity("next");
        });

        //Layout Schematic Logic
        $(".venue__layout .layout__info__options li").on("click", function() {
            const clickedIndex = $(this).index();
            
            if (!venueLayoutImages.eq(clickedIndex).hasClass("active")) {
                $(venueLayoutImages, "active").removeClass("active");
                venueLayoutImages.eq(clickedIndex).addClass("active");

                //Change active li item
                $(venueLayoutListItems,"active").removeClass("active");
                venueLayoutListItems.eq(clickedIndex).addClass("active")
            }
        })
    }


})