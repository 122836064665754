const addonModule = document.querySelector('.addon__module');
    
if (typeof(addonModule) != 'undefined' && addonModule != null) {
    const closeAddon = document.getElementById("close-addon");
    const skipAddon = document.getElementById("skip-addons");

    function openAddonModule() {
        document.querySelector('.addon__module').classList.add('open');
        document.body.classList.add('no-overflow');
    }

    function closeAddonModule() {
        document.querySelector('.addon__module').classList.remove('open');
        document.body.classList.remove('no-overflow');
    }

    closeAddon.addEventListener("click", closeAddonModule, false);
    skipAddon.addEventListener("click", closeAddonModule, false);


    function summaryExpand(e) {
        e.stopPropagation();

        let summaryExpandId = e.target.getAttribute('data-id');
        let summaryExpandContainer = document.querySelector('.expand_container' + '[data-id="' + summaryExpandId + '"] .expand_body');

        if (summaryExpandContainer.style.maxHeight) {
            summaryExpandContainer.style.maxHeight = null;
            document.querySelector('.expand_container' + '[data-id="' + summaryExpandId + '"] .expand_toggle').innerHTML = 'Expand <span>+</span>';
        } else {
            summaryExpandContainer.style.maxHeight = summaryExpandContainer.scrollHeight + "px";
            document.querySelector('.expand_container' + '[data-id="' + summaryExpandId + '"] .expand_toggle').innerHTML = 'Minimise <span>–</span>';
        }
    }

    function addonExpand(e) {
        e.stopPropagation();
        let addonExpandId = e.target.getAttribute('data-id');
        let addonExpandContainer = document.querySelector('.expand_container' + '[data-id="' + addonExpandId + '"]');

        if (addonExpandContainer.style.maxHeight) {
            addonExpandContainer.style.maxHeight = null;
        } else {
            addonExpandContainer.style.maxHeight = addonExpandContainer.scrollHeight + "px";
        }

    }


    const targetNode = document.querySelector(".addon__module .booking__module");
    const config = { attributes: true, childList: true, subtree: true };

    const callback = function(mutationsList, observer) {
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const expandToggles = document.querySelectorAll(".expand_toggle");
                expandToggles.forEach(function(expandToggle) {

                    let dataType = expandToggle.getAttribute('data-type');

                    if(dataType == 'summary'){
                        expandToggle.addEventListener("click", summaryExpand, false);
                    }else{
                        expandToggle.addEventListener("click", addonExpand, false);
                    }
                });
            }
        }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

}