$(function() {

    const partnersBlock = $(".partners__block #slider").flickity({
        cellAlign: "left",
        autoPlay: $(".partners__block .partners__item").length >= 6 ? 2000 : false,
        prevNextButtons: false,        
        pageDots: false,
        imagesLoaded: true,
        wrapAround : $(".partners__block .partners__item").length >= 6 ? 2000 : false,
        contain: false
    })

})