$(function () {

    const bookingScroll = $("a.cta.scroller");

    bookingScroll.on("click", function () {

        $("html, body").animate({
            scrollTop: $(".booking__module").offset().top
        }, 500)
    });

    const productImageSlider = $(".product__slider__inner > #slider");
    productImageSlider.flickity({
        contain: true,
        cellAlign: "left",
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true
    });

    if ($(".single__product__img").length === 1) {
        $("#product-slide-prev, #product-slide-next").hide();
    }

    $("#product-slide-prev").on("click", function () {
        productImageSlider.flickity("previous");
    })

    $("#product-slide-next").on("click", function () {
        productImageSlider.flickity("next");
    });

    $(".info__book__action").on("click", function () {
        if ($(".booking__module").length) {
            $("html, body").animate({
                scrollTop: $(".booking__module").offset().top
            }, 500)
        }
    })

    $("#at20mc > #at15s").css({ "top": "0 !important", "left": "0 !important" })

    //Align sharethis box    
    $("body").on("mouseover", ".at-svc-compact", function () {

        let style = {
            "position": "absolute",
            "top": `${$("#atstbx .at-svc-compact").offset().top + 40}px`,
            "left": `${$("#atstbx .at-svc-compact").offset().left}px`
        };

        $("#at20mc").css(style);

    })
})