const url = new URL(window.location.href);
const isReferred = url.searchParams.get("referrer");

$(function () {

    const tabLinks = $(".tabs__navigation ul li a");
    const tabContentItems = $(".tabs__content .tab__wrapper");
    const mobileTabSelectLink = $(".tabs__navigation__mobile select");
    const tabActiveMarker = $(".tabs__navigation #active-marker");

    const heritageBanner = $(".heritage__banner");

    let sliderHeight = $(tabContentItems, ".show").find(".tab__slider__item").outerHeight();
    $(window).on("resize", function () {
        sliderHeight = $(".tab__wrapper.show").find(".tab__slider__item").outerHeight();
        $(".tab__slider.show").outerHeight(sliderHeight);
    })

    mobileTabSelectLink.on("change", function () {

        const selectedIndex = $(this)[0].selectedIndex;

        if (!tabContentItems.eq(selectedIndex).hasClass("show")) {
            updateTabs(selectedIndex);
        }

    })

    const slideTabBar = (width, offset, index) => {
        tabActiveMarker.css({ "width": width + "px", "left": offset + "px" });

        //Enquire tab colours
        if ($("body").hasClass("enquire")) {

            tabActiveMarker[0].classList = "";

            switch (index) {
                case 0:
                    tabActiveMarker.addClass("course");
                    break;
                case 1:
                    tabActiveMarker.addClass("venue");
                    break;
                case 2:
                    tabActiveMarker.addClass("sport");
                    break;
                case 3:
                    tabActiveMarker.addClass("about");
                    break;
                default:
                    break;
            }

        }

    }

    //If query parameter for pre-selected form, please select    
    if (isReferred) {

        switch (isReferred) {
            case "course":
                slideTabBar(tabLinks.eq(0).width(), 0, 0); 
                updateTabs(0);               
                break;
            case "venue":
                slideTabBar(tabLinks.eq(1).width(), $(".tabs__navigation li").eq(1).position().left + 24, 1);
                updateTabs(1);
                break;
            case "sport":
                slideTabBar(tabLinks.eq(2).width(), $(".tabs__navigation li").eq(2).position().left + 24, 2);
                updateTabs(2);
                break;
            case "about":
                slideTabBar(tabLinks.eq(3).width(), $(".tabs__navigation li").eq(3).position().left + 24, 3);
                updateTabs(3);                
                break;
            case "enquire":
                slideTabBar(tabLinks.eq(3).width(), $(".tabs__navigation li").eq(3).position().left + 24, 3);
                updateTabs(3);
                break;
            default:
                slideTabBar(tabLinks.eq(0).width(), 0, 0);
                updateTabs(0);
                break;
        }

    } else {
        //Initialise first tab
        slideTabBar(tabLinks.eq(0).width(), 0, 0);
    }

    tabLinks.on("click", function () {
        const clickedIndex = $(this).index(".tabs__navigation ul li a");
        const clickedTab = $(".tabs__navigation ul li").eq(clickedIndex);

        //Change active tab
        if (!tabContentItems.eq(clickedIndex).hasClass("active")) {
            updateTabs(clickedIndex);
        }

        //Ternary operator checking if the first element is clicked
        //If it is, know our offset is 0
        slideTabBar(clickedIndex === 0 ? clickedTab.width() : clickedTab.width(), clickedIndex === 0 ? 0 : clickedTab.position().left + 24, clickedIndex);
    })

    function updateTabs(clickedIndex) {

        if ($(".large__tab__slider").attr("id") === "index-tab-slider") {
            $(".tab__wrapper .tab__slider").eq(clickedIndex).outerHeight(sliderHeight)
        }

        $(tabContentItems, "active").removeClass("show").addClass("hide");
        tabContentItems.eq(clickedIndex).addClass("show").removeClass("hide")

        //Index slider animation
        if ($(".large__tab__slider").attr("id") === "index-tab-slider") {
            $(tabContentItems, "active").find(".tab__slider__item").removeClass("visible");
            tabContentItems.eq(clickedIndex).find(".tab__slider__item").addClass("visible");
        }

        //Heritage control
        if ($(".tabs").hasClass("heritage__banner")) {

            if (!heritageBanner.find(".heritage__img").eq(clickedIndex).hasClass("active") &&
                !heritageBanner.find(".heritage__item").eq(clickedIndex).hasClass("active")) {

                heritageBanner.find(".heritage__img.active").removeClass("active")
                heritageBanner.find(".heritage__item.active").removeClass("active")

                heritageBanner.find(".heritage__img").eq(clickedIndex).addClass("active");
                heritageBanner.find(".heritage__item").eq(clickedIndex).addClass("active");

            }
        }

    }

})