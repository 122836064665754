function isset(node){
    if (typeof(node) != 'undefined' && node != null) {
        return true;
    }else{
        return false;
    }
}

const verificationModal = document.querySelector("#verification-modal");

if(isset(verificationModal)){
    const confirmVerification = verificationModal.querySelector('#confirm-verification');

    if(isset(confirmVerification)){
        confirmVerification.addEventListener('click', closeVerificationModal, false);
    }

    function closeVerificationModal(){
        const verificationModal = this.closest("#verification-modal");

        function handler() {
            verificationModal.classList.remove('verifying');
            verificationModal.remove();
            verificationModal.removeEventListener(
                'transitionend', 
                handler, 
                false
            );
        }

        verificationModal.addEventListener(
            'transitionend', 
            handler, 
            false
        );

        verificationModal.classList.add('verifying');

    }
}