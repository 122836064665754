$(function () {

    const menuToggle = $(".menu__toggle");

    const navMenuLinks = $(".nav__menu .nav__menu__links");
    const navMenuSubLinks = $(".nav__menu .nav__menu__sub");

    const navLogo = $("#nav-logo");

    let activeLink = null;

    menuToggle.on("click", _ => {

        if ($("body").hasClass("menu-open")) {
            $(".nav__menu__inner").removeClass("open");
        } else {
            $(".nav__menu__inner").addClass("open");
        }

        $("body").toggleClass("menu-open");
        $("nav.site__nav h1").toggleClass("about-page");

        navMenuSubLinks.removeClass("open");
        $("body").removeClass("menu-sub-open");
    })
    
    navMenuLinks.find("a+span").on("mouseenter click", function (e) {

        const hoveredSubLink = $(this).attr("id");

        //Check for mobile
        if (window.innerWidth <= 768) {
            if (activeLink != null && activeLink != hoveredSubLink) {
                $("#" + activeLink).removeClass("active")
                $("#" + hoveredSubLink).addClass("active")
            }
        }

        //Remove any previously active links
        navMenuSubLinks.find("ul.active").removeClass("active");
        $(this, ".active").removeClass("active")

        if (hoveredSubLink !== undefined) {
            navMenuSubLinks.addClass("open");
            navMenuSubLinks.find("ul#" + hoveredSubLink).addClass("active");
            activeLink = hoveredSubLink;

            $("body").addClass("menu-sub-open");
            $("#" + hoveredSubLink).addClass("active")
        }

    }).on("mouseleave", function () {
        if (window.innerWidth > 768) {

            navMenuSubLinks.removeClass("open");
            navMenuSubLinks.find("ul.active").removeClass("active");
            $("#" + activeLink).removeClass("active")
        }
        
    });

    $(navMenuSubLinks).on("mouseenter", function () {

        if (activeLink) {
            navMenuSubLinks.addClass("open");
            navMenuSubLinks.find("ul#" + activeLink).addClass("active");
            $("#" + activeLink).addClass("active")
        }

    }).on("mouseleave", function () {
        navMenuSubLinks.removeClass("open");
        navMenuSubLinks.find("ul#" + activeLink).removeClass("active");
        $("#" + activeLink).removeClass("active")
        activeLink = null;
    });

    $("#close-sub").on("click", function () {

        navMenuSubLinks.removeClass("open");
        $("body").removeClass("menu-sub-open");
        $("#" + activeLink).removeClass("active")
    });

    //Hide logo if scrolling in menu
    $(".nav__menu__inner").on("scroll", function() {

        if ($(".nav__menu__inner > div").find("div").eq(0).offset().top - 30 <= $(".site__nav").height()) {        
            $(".site__nav").hide();
        } else {
            $(".site__nav").show();
        }

        
    })

    $(".basket > a").on("click", function () {
        $("div#basket-popup").toggle();
    })
})