$(function () {

    if ($("body").hasClass("home")) {
        
        //Sliders
        const indexProductsSlider = $("#index-products-slider div#slider");
        const sliderOptions = {
            draggable: true,
            prevNextButtons: false,
            cellAlign: "left",
            contain: true,
            pageDots: false
        }

        let fHeight = 0;

        indexProductsSlider.flickity({
            draggable: true,
            prevNextButtons: false,
            groupCells: "80%",
            center: true,
            cellAlign: "center",
            contain: true,
            pageDots: false,
            imagesLoaded: true,
            adaptiveHeight: false
        });  

        $("#index-products-slider .flickity-slider").height($("#index-products-slider .flickity-viewport").height())

        $("#index-products-slider .card__item__wrapper").each(function() {
            if (fHeight < $(this).innerHeight()) {
                fHeight = ($(this).innerHeight() - parseInt($(this).css("padding-bottom")));
            }
        })

        indexProductsSlider.find(".flickity-viewport").height(fHeight);

        $(".large__tab__slider#index-tab-slider .tab__slider").each((i) => {
            $(".large__tab__slider#index-tab-slider .tab__slider").eq(i).flickity(sliderOptions);
        })
        
        $(".large__tab__slider .tab__wrapper").eq(0).find(".tab__slider__item").addClass("visible")

        const indexHeaderSlider = $("#index__header #header__slider");
        let slidePauseTimer = false;        

        const slider = indexHeaderSlider.flickity({
            autoPlay: 6000,
            wrapAround: true,
            controls: false,
            draggable: false,
            pauseAutoPlayOnHover: false,
            pageDots: false,
            prevNextButtons: false,
            adaptiveHeight: true,
            fade: true,
            cellAlign : "left",
            setGallerySzie: false,
            on: {
                change: function (slide) {
                    $("#header__slider__nav ul li.active").removeClass("active");
                    $("#header__slider__nav ul li").eq(slide).addClass("active");

                    $(".header-bg.active").removeClass("active");
                    $(".header-bg").eq(slide).addClass("active");

                    $("#header__slider .flickity-viewport").height($("#header__slider").height());

                    $(".site__nav h1")[0].classList = "";

                    switch (slide) {
                        case 0:
                            $(".site__nav h1").addClass("course");
                            break;
                        case 1:
                            $(".site__nav h1").addClass("venue");
                            break;
                        case 2:
                            $(".site__nav h1").addClass("sport");
                            break;
                        case 3:
                            $(".site__nav h1").addClass("about");
                            break;
                    }
                },
                ready: function () {
                    $("#header__slider .flickity-viewport").css("min-height", $("#header__slider").height());
                    $(".site__nav h1").addClass("course");
                    $(".header__slider__item > div").addClass("animate");                    
                }
            }
        })

        $("#header__slider__nav li").on("click", function () {

            const clickedIndex = $(this).index();
            slider.flickity("selectCell", clickedIndex);
            slider.flickity("pausePlayer");
        })

        //If flickity 
    }

})