$(function () {

    //List of classes we will animate on
    const targetClasses = [
        {
            el : ".card__item__wrapper",
            type : "list"
        },
        {
            el : ".venue__slider__item",
            type : "list"
        },        
        {
            el : ".large__tab__slider .container"
        },
        {
            el : ".dynamic-boxes .box__link"
        },
        {
            el : ".about__team__item"
        },
        {
            el : ".featured__banner__img"
        },
        {
            el : ".featured__banner main"
        },
        {
            el : ".benefits__card"
        },
        {
            el : ".booking__module"
        },
        {
            el : ".venue__features .container, .venue__slider h4"
        },
        {
            el : ".venue__layout__img",
            type : "parent"
        },
        {
            el : ".layout__info__options li",
            type : "list"
        },
        {
            el : ".quote__block .container"
        },
        {
            el : ".enquire__block__title"
        },
        {
            el : ".enquire__block__actions a:eq(0)"
        },
        {
            el : ".enquire__block__actions a:eq(1)"
        },
        {
            el : ".partners__item"
        },
        {
            el : ".container.tabs"
        },
        {
            el : ".card__list__filter",
            type : "parent"
        },
        {
            el : ".footer__bottom .container",
            anim : "fade"
        },
        {
            el : ".footer__newsletter .container",
            anim : "fade"
        },
        {
            el : ".card__list__pagination"
        },
        {
            el : ".split__features__desc .desc__inner"
        },
        {
            el : ".split__features .container img",
            anim : "fade"
        }
    ]

    targetClasses.forEach(obj => {
                 
        if (obj.type && obj.type === "list") {
            $(obj.el).parent().addClass("list");

        } else if(obj.type && obj.type === "parent") {

            $(obj.el).parent().addClass("remove2animate animated");

        } else {
            $(obj.el).addClass("remove2animate animated");   
        }

        if (obj.anim && obj.anim === "fade") {
            $(obj.el).addClass("fade");
        }

    });

    if ('IntersectionObserver' in window) {

        var animatedElements = document.querySelectorAll('.remove2animate, .list');

        var animationObserver = new IntersectionObserver(function (animatedElements) {

            for (var i = 0; i < animatedElements.length; i++) {
                var scope = $(animatedElements[i].target);

                if (animatedElements[i]['isIntersecting'] === true) { //when element comes into viewport

                    //bump in sections
                    if (scope.hasClass('remove2animate')) {
                        scope.removeClass('remove2animate');
                    }

                    //animate list items
                    if (scope.hasClass('list')) {
                        scope.addClass('fadeIn');
                    }

                    //once element is in view, we don't have to observe it anymore
                    animationObserver.unobserve(animatedElements[i].target);
                }
            }

        }, { rootMargin: '-50px' }); //show element when 50px is within viewport

        //init observer on all animatable elements
        for (var i = 0; i < animatedElements.length; i++) {
            animationObserver.observe(animatedElements[i]);
        }

    } else { //if intersectionObserver is not supported in browser, use scroll method for checking when elements come into view

        $(window).scroll(function () {

            var windowBottom = $(window).scrollTop() + $(window).height(),
                animateScrollPoint = $(window).height() * 0.15;

            $('.remove2animate, ul.halves li, ul.animateList').each(function () {

                if ($(this).offset().top + animateScrollPoint < windowBottom) {

                    //bump in sections
                    if ($(this).hasClass('remove2animate')) {
                        $(this).removeClass('remove2animate');
                    }

                    //staggered animation of halves
                    if ($(this).is('ul.halves li')) {
                        $(this).find('.image').removeClass('remove2animate');
                        $(this).find('.info').removeClass('remove2animate');
                    }

                    //animate list items
                    if ($(this).is('ul.animateList')) {
                        $(this).addClass('fadeIn');
                    }
                }

            });

            //if scroll gets to page bottom, force remove2animate on all remaining elements that still have class.
            //This is required when there is very short content meaning the animateScrollPoint cannot be reached to trigger animation
            if (windowBottom == $(document).height()) {
                $('.remove2animate').removeClass('remove2animate');
            }

        }).scroll();
    }
    

})