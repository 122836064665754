$(function () {

    const colours = ["0,178,226", "0,187,180", "125,86,197", "0,43,73"];
    const bodyClasses = $("body")[0].classList;
    const documentStyleProperties = document.documentElement.style;
    
    let currentScrollY = 0;

    switch (true) {
        case bodyClasses.contains("course"):
            documentStyleProperties.setProperty("--highlightColour", colours[0]);
            break;
        case bodyClasses.contains("sport"):
            documentStyleProperties.setProperty("--highlightColour", colours[1]);
            break;
        case bodyClasses.contains("venue"):
            documentStyleProperties.setProperty("--highlightColour", colours[2]);
            break;
        case bodyClasses.contains("about"):
            documentStyleProperties.setProperty("--highlightColour", colours[3]);
            break;
        default:
            documentStyleProperties.setProperty("--highlightColour", "#000");
            break;
    }
    
    window.addEventListener("message", event => {

        if ($(".footer__newsletter__form").length) {

            if (event.data.type === "hsFormCallback" && event.data.eventName == "onFormReady") {
                let newsletterSubmit = $(".footer__newsletter__form .hs_submit");
                let hubspotInputId = newsletterSubmit.find(".actions input[type=submit]").attr("data-reactid");
                newsletterSubmit.remove();            
                $(".footer__newsletter__form .hs-email .input").append(`<button class="hs-button primary large" type="submit" data-reactid=${hubspotInputId}></button>`);
            }
        }
    });

    $("body").on("click", "a.at-icon-wrapper", function() {
        currentScrollY = $(window).scrollTop();
    })

    addthis.addEventListener("addthis.menu.open", function(e) {
        $(window).scrollTop(currentScrollY)        
    });    

})